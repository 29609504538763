<template>
  <div>
    <nav
      class="navbar is-fixed-top has-shadow"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <a
          class="navbar-item"
          href="https://initials.app"
        >
          <span class="logo">Initials</span>
        </a>
        <span
          v-if="document"
          class="navbar-item is-pulled-right"
        >
          <small>requested by {{ document.requested_by }} </small>
        </span>
      </div>
    </nav>
    <div v-if="!isDocumentFinalized">
      <nav
        v-if="isPdfLoadingComplete"
        class="navbar has-shadow is-fixed-bottom level tool-bar"
      >
        <div class="level-left">
          <div class="level-item is-pulled-left space-top1">
            <b-button
              size="is-small"
              type="action-button is-primary"
              icon-left="lifebuoy"
              rounded
              @click="showTourDialog = true"
              @handleTourDialogClose="completedIntro"
            />
          </div>
          <div class="level-item is-pulled-left">
            <b-tooltip
              label="Start here"
              multilined
              position="is-top"
              size="is-large"
              :active="showStartButtonHint"
              always
            >
              <b-button
                id="signature-dialog-button"
                type=" action-button"
                outlined
                icon-left="signature-freehand"
                @click="handleSignatureButtonClick"
              >
                Sign
              </b-button>
            </b-tooltip>
          </div>

          <!-- <div class="level-item">
          <b-button
            size="is-medium"
            type="is-text action-button"
            icon-left="signature-text"
          >
            Text
          </b-button>
        </div> -->
        </div>
        <div class="level-right1">
          <div class="level-item is-pulled-right">
            <b-button
              icon-left="content-save-all-outline"
              class="button is-primary"
              @click="onFinish"
            >
              Submit
            </b-button>
          </div>
        </div>
      </nav>
    </div>

    <div class="container ">
      <div id="pdf-wrapper-signature">
        <pdfview
          v-if="pdfFile"
          ref="pdfView"
          :pdf-file="pdfFile"
          :overlays="overlays"
          @handlePdfLoading="handlePdfLoading"
          @onSuccessfullSubmit="onSuccessfullSubmit"
          @onDeleteOverlay="onDeleteOverlay"
        />
      </div>
    </div>
    <signature-dialog
      :visibility="isSignaturePadShown"
      @handleDocumentCloseDialog="handleDocumentCloseDialog"
    />
    <progress-save-dialog
      :visibility="isProgressSaveDialogShown"
      @handleProgressSaveCloseDialog="isProgressSaveDialogShown = false"
    />
    <tour-dialog
      :visibility="showTourDialog"
      @handleTourDialogClose="completedIntro"
    />
  </div>
</template>

<script>
import SignatureDialog from "@/views/pdf_viewer/SignatureDialog";
import PDFView from "@/views/pdf_viewer/PDFView";
import ProgressSaveDialog from "./ProgressSaveDialog";
import TourDialog from "./TourDialog";

import axios from "axios";
import { eventHub } from "@/commons/event-bus";

export default {
  components: {
    pdfview: PDFView,
    SignatureDialog,
    ProgressSaveDialog,
    TourDialog
  },
  props: {
    tokenString: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isSignaturePadShown: false,
      isProgressSaveDialogShown: false,
      overlays: [],
      isPdfLoadingComplete: false,
      isLoading: true,
      pdfFile: null,
      document: null,
      isDocumentFinalized: false,
      scrollY: 0,
      showStartButtonHint: true,
      showTourDialog: false,
      showIntro: true
    };
  },
  watch: {
    tokenString(value) {
      this.getDocument();
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    eventHub.$on("document.failure", this.pdfHandlingError);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    eventHub.$off("document.failure", this.pdfHandlingError);
  },
  mounted() {
    this.showIntro = localStorage.showIntro || true;
    this.getDocument();
  },
  methods: {
    handleSignatureButtonClick() {
      this.isSignaturePadShown = true;
      if (this.showIntro) {
        this.showStartButtonHint = false;
      }
    },
    handleScroll(event) {
      this.scrollY = window.pageYOffset;
    },
    async onFinish() {
      let self = this;
      if (this.overlays.length == 0) {
        this.$buefy.toast.open({
          message: "Document has not changed."
        });
        return;
      }
      let pdfBlob = await this.$refs.pdfView.onFinish().catch(function(e){
            self.$buefy.toast.open({
              type: 'is-danger',
              message: 'PDF is encrypted. Cannot sign the PDF.',
            });
      });
      this.finalizeFile(pdfBlob);
    },
    finalizeFile(blob) {
      if (!(blob instanceof Blob)) {
        return;
      }
      this.isProgressSaveDialogShown = true;
      this.isLoading = true;
      let url = "/shares/documents/signature?token=" + this.tokenString;
      let formData = new FormData();
      formData.append("file", blob);
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
          }
        })
        .then(response => {
          this.isLoading = false;
          this.$buefy.toast.open("File submitted successfully! ");
          this.onSuccessfullSubmit();
          eventHub.$emit("document.submitted", true);
        })
        .catch(e => {
          this.isLoading = false;
          this.$buefy.toast.open({
            type: "is-danger",
            message: "Cannot upload file."
          });
          eventHub.$emit("document.submitted", false);
        });
    },
    randomId: function() {
      //https://gist.github.com/gordonbrander/2230317
      // Math.random should be unique because of its seeding algorithm.
      // Convert it to base 36 (numbers + letters), and grab the first 9 characters
      // after the decimal.
      return (
        "_" +
        Math.random()
          .toString(36)
          .substr(2, 9)
      );
    },
    handlePdfLoading() {
      this.isPdfLoadingComplete = true;
      //this.$refs.tourView.startTour();
      if (this.showIntro == true) {
        this.showTourDialog = true;
      }
    },
    addOverlaySignature(flag,imag) {
      this.isSignaturePadShown = false;
      var img = document.createElement("img");
      img.setAttribute("type", "hidden");
      img.src = imag;
      img.onload = function() {
        this.overlays.push({
          id: this.randomId(),
          image: img,
          initialX:
            document.getElementById("overlay").getBoundingClientRect().width /
              2 -
            (img.width * 0.3) / 2,
          initialY: this.scrollY + window.innerHeight / 2 - img.height * 0.3,
          width: img.width,
          height: img.height
        });
        img.remove();
      }.bind(this);
    },
    handleDocumentCloseDialog(flag, imag) {
      self = this;
      // Dirty cheap hack to get the scrollY position after the signature dialog is closed.
      // When signature dialog is open, the scrollY position is set 0 to make the dialog from scrolling
      setTimeout(function() { self.addOverlaySignature(flag,imag);}, 300);
    },
    onSuccessfullSubmit() {
      this.reloadPDF();
    },
    reloadPDF() {
      this.pdfFile = null;
      this.getDocument();
    },
    getDocument() {
      this.isLoading = true;

      axios
        .get("/shares/documents/info?token=" + this.tokenString)
        .then(response => {
          this.isLoading = false;
          this.pdfFile =
            process.env.VUE_APP_BASE_URL +
            "/users/files/download?file=" +
            response.data.document.url;
          this.overlays = [];
          this.share = response.data;
          this.document = response.data.document;
          this.isDocumentFinalized = response.data.is_complete;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(e => {
          this.isLoading = false;
        });
    },
    onDeleteOverlay(item, overlayItems) {
      const index = overlayItems.findIndex(x => x.id === item.id);
      overlayItems.splice(index, 1);
      this.overlays = [];
      overlayItems.forEach(item => {
        // Change the original initial position to the current X,Y screen position
        // upon removal of item
        item.initialX = item.screenX;
        item.initialY = item.screenY;
        this.overlays.push(item);
      });
    },
    pdfHandlingError() {
      this.$buefy.toast.open({
        type: "is-danger",
        message: "Cannot download file from server to finalize."
      });
    },
    completedIntro() {
      this.showTourDialog = false;
      localStorage.showIntro = false;
      this.showIntro = false;
    }
  }
};
</script>

<style scoped>
#pdf-wrapper-signature {
  margin-bottom: 150px;
  margin-top: 80px;
}

.level:not(:last-child) {
  margin-bottom: 0px;
}

.action-button {
  text-decoration: unset;
}


</style>
