var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "nav",
        {
          staticClass: "navbar is-fixed-top has-shadow",
          attrs: { role: "navigation", "aria-label": "main navigation" }
        },
        [
          _c("div", { staticClass: "navbar-brand" }, [
            _vm._m(0),
            _vm.document
              ? _c("span", { staticClass: "navbar-item is-pulled-right" }, [
                  _c("small", [
                    _vm._v(
                      "requested by " + _vm._s(_vm.document.requested_by) + " "
                    )
                  ])
                ])
              : _vm._e()
          ])
        ]
      ),
      !_vm.isDocumentFinalized
        ? _c("div", [
            _vm.isPdfLoadingComplete
              ? _c(
                  "nav",
                  {
                    staticClass:
                      "navbar has-shadow is-fixed-bottom level tool-bar"
                  },
                  [
                    _c("div", { staticClass: "level-left" }, [
                      _c(
                        "div",
                        { staticClass: "level-item is-pulled-left space-top1" },
                        [
                          _c("b-button", {
                            attrs: {
                              size: "is-small",
                              type: "action-button is-primary",
                              "icon-left": "lifebuoy",
                              rounded: ""
                            },
                            on: {
                              click: function($event) {
                                _vm.showTourDialog = true
                              },
                              handleTourDialogClose: _vm.completedIntro
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "level-item is-pulled-left" },
                        [
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: "Start here",
                                multilined: "",
                                position: "is-top",
                                size: "is-large",
                                active: _vm.showStartButtonHint,
                                always: ""
                              }
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    id: "signature-dialog-button",
                                    type: " action-button",
                                    outlined: "",
                                    "icon-left": "signature-freehand"
                                  },
                                  on: { click: _vm.handleSignatureButtonClick }
                                },
                                [_vm._v(" Sign ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "level-right1" }, [
                      _c(
                        "div",
                        { staticClass: "level-item is-pulled-right" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "button is-primary",
                              attrs: {
                                "icon-left": "content-save-all-outline"
                              },
                              on: { click: _vm.onFinish }
                            },
                            [_vm._v(" Submit ")]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _c("div", { staticClass: "container " }, [
        _c(
          "div",
          { attrs: { id: "pdf-wrapper-signature" } },
          [
            _vm.pdfFile
              ? _c("pdfview", {
                  ref: "pdfView",
                  attrs: { "pdf-file": _vm.pdfFile, overlays: _vm.overlays },
                  on: {
                    handlePdfLoading: _vm.handlePdfLoading,
                    onSuccessfullSubmit: _vm.onSuccessfullSubmit,
                    onDeleteOverlay: _vm.onDeleteOverlay
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c("signature-dialog", {
        attrs: { visibility: _vm.isSignaturePadShown },
        on: { handleDocumentCloseDialog: _vm.handleDocumentCloseDialog }
      }),
      _c("progress-save-dialog", {
        attrs: { visibility: _vm.isProgressSaveDialogShown },
        on: {
          handleProgressSaveCloseDialog: function($event) {
            _vm.isProgressSaveDialogShown = false
          }
        }
      }),
      _c("tour-dialog", {
        attrs: { visibility: _vm.showTourDialog },
        on: { handleTourDialogClose: _vm.completedIntro }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "navbar-item", attrs: { href: "https://initials.app" } },
      [_c("span", { staticClass: "logo" }, [_vm._v("Initials")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }