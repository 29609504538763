var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "document-dialog",
      style: { width: _vm.$responsive.width + "px" },
      attrs: { active: _vm.dialog, scroll: "keep", "can-cancel": false },
      on: {
        "update:active": function($event) {
          _vm.dialog = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: " dialog modal-card", staticStyle: { width: "auto" } },
        [
          _c("header", { staticClass: "modal-card-head" }, [
            _c("p", { staticClass: "modal-card-title" }, [
              _vm.isSavingProgress
                ? _c("span", [_vm._v("Saving... ")])
                : _vm._e(),
              _vm.isSavingComplete
                ? _c("span", [_vm._v("Success ")])
                : _vm._e(),
              _vm.isSavingFailed ? _c("span", [_vm._v("Failed ")]) : _vm._e()
            ])
          ]),
          _c("section", { staticClass: "modal-card-body" }, [
            _vm.isSavingProgress
              ? _c(
                  "div",
                  { staticClass: "save-progress-bar" },
                  [_c("b-progress", { attrs: { type: "is-success" } })],
                  1
                )
              : _vm._e(),
            _vm.isSavingFailed
              ? _c("div", [
                  _c("p", [
                    _vm._v(
                      " Cannot save the document. Please try again later. "
                    )
                  ])
                ])
              : _vm._e(),
            _vm.isSavingComplete
              ? _c("div", [
                  _c("p", [
                    _vm._v(
                      " Document has been saved with your changes. A copy of this document with your changes will be sent in email. "
                    )
                  ])
                ])
              : _vm._e()
          ]),
          _c("footer", { staticClass: "modal-card-foot" }, [
            _c("div", { staticClass: "level action-buttons" }, [
              _c("div", { staticClass: "level-left" }),
              _c(
                "div",
                { staticClass: "level-right" },
                [
                  _vm.isSavingComplete || _vm.isSavingFailed
                    ? _c(
                        "b-button",
                        {
                          staticClass: "button is-primary",
                          attrs: { rounded: "" },
                          on: {
                            click: function($event) {
                              return _vm.handleClose()
                            }
                          }
                        },
                        [_vm._v(" close ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }