<template>
  <b-modal
    :active.sync="dialog"
    scroll="keep"
    :can-cancel="false"
    class="help-dialog"
    :style="{ width: $responsive.width + 'px' }"
  >
    <div
      class=" dialog modal-card"
      style="width: auto"
    >
      <section class="modal-card-body">
        <b-carousel
          icon-size="is-large"
          :arrow-hover="false"
          :autoplay="false"
          :repeat="true"
        >
          <b-carousel-item
            v-for="(carousel, i) in carousels"
            :key="i"
          >
            <section class="hero is-medium ">
              <div class="has-text-centered">
                <p class="title">
                  {{ carousel.title }}
                </p>
                <img
                  :src="carousel.imageHelp"
                  width="80%"
                  height="auto"
                >
              </div>
            </section>
          </b-carousel-item>
        </b-carousel>
      </section>
      <footer class="modal-card-foot">
        <div class="level action-buttons">
          <div class="level-left">
            <b-button
              class="button"
              type="button"
              rounded
              @click="cancel()"
            >
              Close
            </b-button>
          </div>
          <div class="level-right">
            <b-button
              class="button is-primary"
              :loading="isSavingProgress"
              rounded
              @click="finish()"
            >
              Finish
            </b-button>
          </div>
        </div>
      </footer>
    </div>
  </b-modal>
</template>
<script>
import isValidEmail from 'is-valid-email';
import axios from 'axios';

export default {
  props: {
    visibility: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: false,
      isSavingProgress: false,
      isDeletingProgress: false,
      selectedModuleIdToMove: -1,
      emails: [],
      message: null,
      carousels: [
        { title: 'Add or remove signatures', imageHelp: '/add_remove.gif' },
        { title: 'Move the signature to the position', imageHelp: '/move_sign.gif' },
        { title: 'Resize as required', imageHelp: '/resize_sign.gif' },
      ],
    };
  },
  watch: {
    visibility(value) {
      this.dialog = value;
    },
  },
  methods: {
    finish() {
      this.dialog = false;
      this.$emit('handleTourDialogClose', this.dialog, true);
    },
    cancel() {
      this.dialog = false;
      this.$emit('handleTourDialogClose', this.dialog, false);
    }
  },
};
</script>

<style scoped>
.help-dialog {
  z-index: 1;
}

.modal {
  z-index: 100;
}
.help-dialog > .modal-background {
  background-color: #0a0a0a94 !important;
}

.modal-background {
    background-color: #0a0a0a94 !important;
}

.modal-card-foot {
  padding: 10px;
}

.modal-card-foot {
  border-top: none;
}

.action-buttons {
  width: 100%;
}
.signature-pad {
  border: 1px solid #cccccc;
}
/* .modal-background {
  margin: 0;
  height: 100%;
  overflow: hidden;
  height: 100%;
  overflow-y: scroll;
} */
</style>
