var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "help-dialog",
      style: { width: _vm.$responsive.width + "px" },
      attrs: { active: _vm.dialog, scroll: "keep", "can-cancel": false },
      on: {
        "update:active": function($event) {
          _vm.dialog = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: " dialog modal-card", staticStyle: { width: "auto" } },
        [
          _c(
            "section",
            { staticClass: "modal-card-body" },
            [
              _c(
                "b-carousel",
                {
                  attrs: {
                    "icon-size": "is-large",
                    "arrow-hover": false,
                    autoplay: false,
                    repeat: true
                  }
                },
                _vm._l(_vm.carousels, function(carousel, i) {
                  return _c("b-carousel-item", { key: i }, [
                    _c("section", { staticClass: "hero is-medium " }, [
                      _c("div", { staticClass: "has-text-centered" }, [
                        _c("p", { staticClass: "title" }, [
                          _vm._v(" " + _vm._s(carousel.title) + " ")
                        ]),
                        _c("img", {
                          attrs: {
                            src: carousel.imageHelp,
                            width: "80%",
                            height: "auto"
                          }
                        })
                      ])
                    ])
                  ])
                }),
                1
              )
            ],
            1
          ),
          _c("footer", { staticClass: "modal-card-foot" }, [
            _c("div", { staticClass: "level action-buttons" }, [
              _c(
                "div",
                { staticClass: "level-left" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button",
                      attrs: { type: "button", rounded: "" },
                      on: {
                        click: function($event) {
                          return _vm.cancel()
                        }
                      }
                    },
                    [_vm._v(" Close ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "level-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button is-primary",
                      attrs: { loading: _vm.isSavingProgress, rounded: "" },
                      on: {
                        click: function($event) {
                          return _vm.finish()
                        }
                      }
                    },
                    [_vm._v(" Finish ")]
                  )
                ],
                1
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }